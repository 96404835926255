import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import SecureSS, { JWT_TOKEN } from "../utils/secure-xs/secureSS";

const DOWNLOAD_FILE = "DOWNLOAD_FILE/";
const createAxiosInstance = baseURL => {
  const HEADERS = {
    "X-Requested-With": "XMLHttpRequest",
    "Access-Control-Allow-Origin": baseURL, // Replace with your domain
    "Access-Control-Allow-Headers": "Content-Type, Authorization",
    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE"
  };

  const axiosInstance = axios.create({
    headers: HEADERS,
    baseURL
  });

  /**
   * Interceptor function to modify the request configuration before sending the request.
   * Adds JWT token to request headers for authentication and sets response type to "blob" for file downloads.
   * @param {Object} config - The request configuration object.
   * @returns {Object} - The modified request configuration.
   */
  // Add a request interceptor
  axiosInstance.interceptors.request.use(config => {
    // Get the JWT token from the SecureSS
    const token = SecureSS.get(JWT_TOKEN);

    // Add the token to the request header
    config.headers.Authorization = `Bearer ${token}`;

    // If the URL starts with "/downloadFile", set the responseType to "blob"
    // and remove the "/downloadFile" prefix from the URL.
    if (config.url.startsWith(DOWNLOAD_FILE)) {
      config.url = config.url.replace(DOWNLOAD_FILE, "");
      config.responseType = "blob";
    }

    return config;
  });
  const handleRequest = async (method, path, data, errorHandler) => {
    try {
      const response = await axiosInstance[method](path, data);
      // TODO: Add a check for the response status code and handle it accordingly
      return { ...response, body: response.data };
    } catch (error) {
      if (errorHandler) {
        throw errorHandler(error);
      }
      else {
        throw handleError(error);
      }
    }
  };

  const handleRequestForFile = async (method, path, data) => {
    return handleRequest(method, DOWNLOAD_FILE + path, data);
  };

  return {
    getRequest: path => handleRequest("get", path),
    postRequest: (path, data, errorHandler) => handleRequest("post", path, data, errorHandler),
    putRequest: (path, data) => handleRequest("put", path, data),
    delRequest: path => handleRequest("delete", path),
    postFileRequest: (path, data) => handleRequestForFile("post", path, data),
    getFileRequest: path => handleRequestForFile("get", path)
  };
};

const handleInvalidSession = ({ statusText, noRedirectToPreviousPage }) => {
  toast.error("Invalid session, Please login to continue");
  sessionStorage.clear();

  if (window?.location?.assign) {
    if (noRedirectToPreviousPage) {
      window.location.assign("/");
    } else {
      window.location.assign(
        "/?go2=" + encodeURIComponent(window.location.pathname + window.location.search)
      );
    }
  }
  return statusText;
};
export const handleError = error => {
  const { response, message } = error;
  if (response) {
    const { status, statusText, data } = response;
    switch (status) {
      case 401:
        return handleInvalidSession({ statusText });

      case 403:
        return handleInvalidSession({ statusText, noRedirectToPreviousPage: true });

      case 417:
        if (data) {
          return data;
        }
        break;

      case 400:
      case 500:
        toast.error(
          <div>
            <div>
              <b>Message:</b> {statusText}
            </div>
            <div>
              <b>Status code:</b> {status}
            </div>
            <div>{data.message || data.error || JSON.stringify(data)}</div>
          </div>
        );
        return statusText;

      default:
        toast.error(status);
    }
  }
  return message;
};

export default createAxiosInstance;
